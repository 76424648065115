import type { ViewCollaboration } from '@ramdam/core';
import type { Flag } from '@ramdam/utils';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { formatApiError } from '~/api/utils/formatApiError';
import { Avatar } from '~/components/Avatar';
import { Button } from '~/components/Button';
import { Dialog, DialogHeader, DialogContent, DialogFooter } from '~/components/Dialog';
import { useFlag } from '~/components/Flag/hooks/useFlag';
import { Icon } from '~/components/Icon';
import { Link } from '~/components/Link';
import { Tooltip, TooltipContent, TooltipTrigger } from '~/components/tooltip/Tooltip';
import { QuestionMarkWithTooltip } from '~/features/client/tiers/components/CampaignPrediction/QuestionMarkWithTooltip';
import { flagCollaboration } from '~/features/collaborations/api/flagCollaboration';
import { useAppTracking } from '~/features/tracking/app/hooks/useAppTracking';
import { useUser } from '~/features/users/useUser';
import { isClientUser } from '~/features/users/utils/isClientUser';
import { useToast } from '~/hooks/useToast';
import { safeAbsurd } from '~/utils/safeAbsurd';
import { truncateMiddle } from '~/utils/string/truncateMiddle';

interface ReviewPostSuccessDialogContextType {
  showReviewPostSuccessDialog: (collaboration: ViewCollaboration) => void;
  hideReviewPostSuccessDialog: () => void;
}

const ReviewPostSuccessDialogContext = createContext<ReviewPostSuccessDialogContextType | null>(null);

interface ReviewPostSuccessDialogProps {
  isOpen: boolean;
  onClose: () => void;
  collaboration: ViewCollaboration;
}

function ReviewPostSuccessDialog({ isOpen, onClose, collaboration }: ReviewPostSuccessDialogProps) {
  const { user } = useUser();
  const isClient = user && isClientUser(user);

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <DialogHeader onClose={onClose}>Post validation</DialogHeader>
      <DialogContent>
        <div className="flex flex-col">
          <div className="mb-2 flex items-center justify-center rounded-lg border border-initial bg-warmgray-50 p-4">
            <span className="mr-1 text-s-regular">You have validated the post from</span>
            <Avatar
              username={collaboration.socialMediaAccount.username}
              src={collaboration.creator.profilePictureUrl}
              size="xs"
              className="mr-1"
            />
            <span className="mr-1 text-s-semibold">{collaboration.socialMediaAccount.username}</span>
          </div>
          <p className="mb-4 text-s-regular">
            Here is the post details. You can find them on resources later on the campaign detail
          </p>
          <div className="flex flex-col">
            <PostUrl postUrl={collaboration.deliverable.postUrl} />
            <AdCode collaboration={collaboration} />
          </div>
        </div>
      </DialogContent>
      <DialogFooter className={isClient ? 'justify-between' : 'justify-end'}>
        {isClient ? (
          <div className="flex">
            <FlagButton collaboration={collaboration} />
            <QuestionMarkWithTooltip
              tooltipContent="Easily mark collaborations you've processed by adding a visual indicator. This helps you quickly identify which ones are complete at a glance. For example, flag a collaboration after using the content or applying an ad code on your advertising platform."
              className="mt-1 text-[12px]"
            />
          </div>
        ) : null}
        <Button variant="primary" color="blue" type="button" onClick={onClose}>
          Close
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

interface ReviewPostSuccessDialogProviderProps {
  children: React.ReactNode;
}

export function ReviewPostSuccessDialogProvider({ children }: ReviewPostSuccessDialogProviderProps) {
  const [dialogState, setDialogState] = useState<{
    isOpen: boolean;
    collaboration?: ViewCollaboration;
  }>({
    isOpen: false,
  });

  const showReviewPostSuccessDialog = useCallback(
    (collaboration: ViewCollaboration) => setDialogState({ isOpen: true, collaboration }),
    [],
  );

  const hideReviewPostSuccessDialog = useCallback(() => setDialogState({ isOpen: false }), []);

  const value = useMemo(
    () => ({
      showReviewPostSuccessDialog,
      hideReviewPostSuccessDialog,
    }),
    [showReviewPostSuccessDialog, hideReviewPostSuccessDialog],
  );

  return (
    <ReviewPostSuccessDialogContext.Provider value={value}>
      {children}
      {dialogState.collaboration && (
        <ReviewPostSuccessDialog
          isOpen={dialogState.isOpen}
          onClose={() => setDialogState({ isOpen: false })}
          collaboration={dialogState.collaboration}
        />
      )}
    </ReviewPostSuccessDialogContext.Provider>
  );
}

export function useReviewPostSuccessDialog() {
  const context = useContext(ReviewPostSuccessDialogContext);
  if (!context) throw new Error('useReviewPostSuccessDialog must be used within ReviewPostSuccessDialogProvider');
  return context;
}

function PostUrl({ postUrl }: { postUrl?: string | null }) {
  const [showTooltip, setShowTooltip] = useState(false);

  if (!postUrl) return null;

  return (
    <div className="mb-4 flex items-center justify-between gap-1">
      <div className="flex flex-col">
        <span className="text-s-semibold">Post URL</span>
        <Link to={postUrl} target="_blank" rel="noreferrer" variant="tertiary">
          <span className="text-s-regular">{truncateMiddle(postUrl)}</span>
        </Link>
      </div>
      <Button
        type="button"
        variant="secondary"
        color="default"
        shape="square"
        size="md"
        className="mr-0.5 h-[30px] w-[30px]"
        title="Copy to clipboard"
        onClick={() => {
          window.navigator.clipboard.writeText(postUrl);
          setShowTooltip(true);
          setTimeout(() => setShowTooltip(false), 1000);
        }}
      >
        <Tooltip placement="top" open={showTooltip}>
          <TooltipTrigger>
            <Icon name="copy" />
          </TooltipTrigger>
          <TooltipContent>Copied</TooltipContent>
        </Tooltip>
      </Button>
    </div>
  );
}

function AdCode({ collaboration }: { collaboration: ViewCollaboration }) {
  const { track } = useAppTracking();
  const [showTooltip, setShowTooltip] = useState(false);

  const adCode = collaboration.deliverable.adCode;
  if (!adCode) return null;

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col">
        <span className="text-s-semibold">Ad code</span>
        <span aria-label={adCode} className="text-s-regular">
          {truncateMiddle(adCode)}
        </span>
      </div>
      <Button
        type="button"
        variant="secondary"
        color="default"
        shape="square"
        className="mr-0.5 h-[30px] w-[30px]"
        size="md"
        title="Copy to clipboard"
        onClick={() => {
          track({
            type: 'collaboration-event',
            name: 'copy-ad-code',
            data: {
              adCode,
              collaborationId: collaboration.id,
            },
          });
          window.navigator.clipboard.writeText(adCode);
          setShowTooltip(true);
          setTimeout(() => setShowTooltip(false), 1000);
        }}
      >
        <Tooltip placement="top" open={showTooltip}>
          <TooltipTrigger>
            <Icon name="copy" />
          </TooltipTrigger>
          <TooltipContent>Copied</TooltipContent>
        </Tooltip>
      </Button>
    </div>
  );
}

function FlagButton({ collaboration }: { collaboration: ViewCollaboration }) {
  const { toast } = useToast();

  const { mutate, isPending } = useFlag({
    onSuccess: () => {
      collaboration.flag = getNextFlag(collaboration.flag);
      toast.success(`Post successfully ${collaboration.flag === 'flagged' ? 'flagged' : 'unflagged'}`);
    },
    onError(error) {
      toast.error(formatApiError(error));
    },
    updateFlagFn: flagCollaboration,
  });

  const onClick = () => {
    if (isPending) return;
    mutate({
      id: collaboration.id,
      flag: getNextFlag(collaboration.flag),
    });
  };

  return (
    <Button
      type="button"
      variant="tertiary"
      color="blue"
      size="md"
      onClick={onClick}
      title="Flag this collaboration"
      className="flex items-center"
    >
      <Icon name={collaboration.flag === 'flagged' ? 'flagged' : 'unflagged'} className="mr-1" />
      {collaboration.flag === 'flagged' ? 'Unflag this collaboration' : 'Flag this collaboration'}
    </Button>
  );
}

function getNextFlag(flag: Flag): Flag {
  switch (flag) {
    case 'flagged':
      return 'none';
    case 'none':
      return 'flagged';
    default:
      return safeAbsurd(flag, 'none', 'invalid_flag');
  }
}
